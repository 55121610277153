import React from "react";
import styles from "./styles.module.scss";
import { TFeatureListWithImage } from "@page-builder/ts/generated";
import { replacePlaceholders } from "@lib/sharedUtils";
import { useDomainContext } from "@hooks/useDomainContext";

const FeatureListWithImage = ({
    props = {},
}: {
    props: TFeatureListWithImage["props"];
}) => {
    const { image, buttonText, withScrollButton, items, title, description } =
        props;
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    const { category, visitDetails } = useDomainContext();

    return (
        <section className={styles["feature-list-with-image-preview"]}>
            <div className={styles["feature-list"]}>
                <div>
                    {title && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: title
                                    ? replacePlaceholders(title, {
                                          category: category?.name,
                                          region: visitDetails.region,
                                          city: visitDetails?.city,
                                      })
                                    : "",
                            }}
                            className={styles.title}
                        />
                    )}
                    {description && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: description
                                    ? replacePlaceholders(description, {
                                          category: category?.name,
                                          region: visitDetails.region,
                                          city: visitDetails?.city,
                                      })
                                    : "",
                            }}
                            className={styles["description"]}
                        />
                    )}
                    <div className={styles["items-container"]}>
                        {items?.map((item, index) => (
                            <div key={index} className={styles.item}>
                                <div className={styles["item-image-container"]}>
                                    <div className={styles.image}>
                                        {item.image ? (
                                            <img
                                                alt={item.title}
                                                src={item.image}
                                            />
                                        ) : (
                                            <div className={styles.counter}>
                                                {index + 1}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    {item.title && <h3>{item.title}</h3>}
                                    {item.description && (
                                        <p>{item.description}</p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {withScrollButton && (
                    <button
                        className={styles.scrollToTopPreview}
                        onClick={scrollToTop}
                    >
                        {buttonText}
                    </button>
                )}
            </div>
            <div className={styles.imageContainer}>
                {image ? <img src={image ?? ""} alt="image" /> : null}
            </div>
        </section>
    );
};

export default FeatureListWithImage;
